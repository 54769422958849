import { useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { useEffect, useState } from "react";
import apiClient from "../../../http/common";
import useClaimHook from "../../../hooks/useClaimHook";
import { FaChevronDown } from "react-icons/fa";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/shadch/ui/ui/dropdown-menu";
import SelectModalDialog from "./SelectModalDialog";

import submitted from "../../../assets/icons/submitted.svg";
import inreview from "../../../assets/icons/inreview.svg";
import valid from "../../../assets/icons/valid.svg";
import invalid from "../../../assets/icons/invalid.svg";

const tableHeader = [
  "Beneficiary Name",
  "Claim Type",
  "Claim Status",
  "Date of Event",
  "Actions",
];

const Claims = () => {
  const length = tableHeader.length;
  const {
    claims,
    isLoading,
    setIsOpen,
    isOpen,
    refetchClaims,
    selectedItem,
    handleSelect,
  } = useClaimHook();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [filteredClaims, setFilteredClaims] = useState([]); // State for filtered claims
  const [currentIndex, setCurrentIndex] = useState(0);
  const [statusIndex, setStatusIndex] = useState(0); // State for selected status index
  // Status text mapping
  const statusText = {
    0: "Submitted",
    1: "InReview",
    2: "Invalid",
    3: "Valid",
  };
  console.log({ claims, filteredClaims });

  const handleSelectItem = (value,index, statusIdx) => {
    setCurrentIndex(index);
    handleSelect(value);
    setStatusIndex(statusIdx);
  }

  // Update filtered claims whenever claims or searchQuery changes
  useEffect(() => {
    if (searchQuery) {
      const filtered = claims?.data?.filter((item) =>
        item.beneficiaryFirstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredClaims(filtered);
    } else {
      setFilteredClaims(claims?.data); // Show all claims if no search query
    }
  }, [claims, searchQuery]);
  return (
    <div className="pt-10 w-full">
      <div className="text flex justify-between">
        <div className="text bg-[#F7F7F7] w-1/3 flex items-center rounded-full px-3 gap-4 mt-4">
          <input
            type="search"
            placeholder="Search for beneficiary"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
            className="text-xs bg-[#F7F7F7] w-full rounded-full py-1 border-0 outline-none"
          />
        </div>
      </div>

      <div className="text mt-8">
        <div
          className={`text-xs grid bg-[#F8F8FA] py-3 ${
            length === 5 ? "grid-cols-5" : "grid-cols-4"
          } font-bold text-start pl-3`}
        >
          {tableHeader.map((header, index) => (
            <div key={index} className="text border bg-gray-200 p-4">
              {header}
            </div>
          ))}
        </div>

        {!isLoading &&
          filteredClaims?.map((item, index) => (
            <div
              key={item.id}
              className={`text-xs grid py-5 border-b ${
                length === 5 ? "grid-cols-5" : "grid-cols-4"
              } text-start pl-3`}
            >
              <div className="text px-4">{item.beneficiaryFirstName}</div>
              <div className="text px-4">
                {item.manualClaimStatus === 0 ? "Surgery" : "Death"}
              </div>
              <div className="text px-4">
                <div
                  className={`px-3 py-2 rounded w-[100px] text-center ${
                    item.manualClaimStatus === 3
                      ? "bg-[#04C5511A]"
                      : item.manualClaimStatus === 2
                      ? "bg-[#EB57571A]"
                      : item.manualClaimStatus === 0
                      ? "bg-[#2CBCEF1A]"
                      : "bg-[#FAB5131A]"
                  }`}
                >
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex  gap-4 items-center">
                      <div className="text flex gap-2 items-center justify-between">
                        {statusText[item.manualClaimStatus] || "Unknown"}
                        <FaChevronDown />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuSeparator />
                      {Object.entries(statusText).map(([key, value], idx) => (
                        <DropdownMenuItem
                          key={key}
                          onClick={() => handleSelectItem(value, index, idx)}
                        >
                          <img
                            className="mr-1"
                            src={
                              value === "Submitted"
                                ? submitted
                                : value === "InReview"
                                ? inreview
                                : value === "Invalid"
                                ? invalid
                                : valid
                            }
                            alt={value}
                          />
                          {value}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
              <div className="text px-4">{item.dateOfEvent}</div>
              <div className="text px-4">
                <button
                  onClick={() =>
                    navigate(`/heirs-claims-details/${item.id}`, {
                      state: item,
                    })
                  }
                  className="text-[#2CBCEF] font-medium"
                >
                  View
                </button>
              </div>
            </div>
          ))}

        {isLoading && (
          <div className="text w-full h-[50vh] flex justify-center items-center">
            <PropagateLoader />
          </div>
        )}
      </div>
      <SelectModalDialog
        text={statusText?.[statusIndex]}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={filteredClaims?.[currentIndex]?.id}
        refetchClaims={refetchClaims}
      />
    </div>
  );
};

export default Claims;
