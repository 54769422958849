import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/shadch/ui/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../components/shadch/ui/ui/form";
import { Textarea } from "../../../components/shadch/ui/ui/textarea";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { IoIosSend } from "react-icons/io";
import { Input } from "../../../components/shadch/ui/ui/input";
import { IoMdAttach } from "react-icons/io";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/shadch/ui/ui/tooltip";
import { FiDelete } from "react-icons/fi";
import pdf from "../../../assets/icons/pdf.svg";
import { RxCross2 } from "react-icons/rx";
import apiClient from "../../../http/common";
import { toast } from "react-toastify";
import {useUser} from "../../../hooks/user-hook";

const formSchema = z.object({
  message: z
    .string()
    .min(5, { message: "Message should be at least 5 letters" }),
});

const SelectModalDialog = ({ text, isOpen, setIsOpen, id, refetchClaims }) => {
  const fileInputRef = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const { userDetails } = useUser();
  console.log(userDetails); // Debugging: log userId

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
      file: null,
    },
  });

  const statusEnum = {
    Submitted: 0,
    InReview: 1,
    Invalid: 2,
    Valid: 3,
  };

  console.log("Status Enum:", statusEnum); // Logs the statusEnum mapping
  console.log("Text received:", text); 

  const onSubmit = (data) => {
    const getValues = form.getValues();
  
    console.log("Status Enum:", statusEnum); // Logs the statusEnum mapping
    console.log("Text received:", text); // Logs the passed text
  
    const numericStatus = statusEnum[text]; // Direct lookup from statusEnum
  
    console.log("Mapped Numeric Status:", numericStatus); // Verify numeric status
  
    if (numericStatus === undefined) {
      console.error("Invalid status text:", text);
      toast.error("Invalid status selection. Please try again.");
      return;
    }
  
    const formData = new FormData();
    formData.append("Comment", getValues.message);
    formData.append("Files", getValues.file);
    formData.append("NewStatus", numericStatus); // Correct numeric value
    formData.append("ManualClaimId", id);
    formData.append("UserId", userDetails?.id);
  
    // Debugging: Log FormData contents
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": ", pair[1]);
    }
  
    apiClient
      .post(`/sponsor/UpdateManualClaimStatus`, formData)
      .then((response) => {
        toast.info(response?.data?.message, {
          position: "top-right",
        });
        form.unregister("message");
        form.unregister("file");
        setIsOpen(false);
  
          refetchClaims();
        
      })
      .catch((error) => {
        console.error("Error submitting form:", error.response || error);
        toast.error("Failed to update status. Please try again.");
      });
  };
      
  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file); 
    form.setValue("file", file);
    if (file) {
      setSelectedFileName(file.name);
    }
  };

  return (
    <Dialog className="" open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <DialogContent className="min-w-[40%]">
        <DialogHeader>
          <DialogTitle className="p-5 text-3xl font-semibold">
            Add a Comment
          </DialogTitle>
          <DialogDescription>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="border-0 px-5 text-lg outline-none relative space-y-6"
              >
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="relative">
                          <Textarea
                            placeholder="Enter Message"
                            className="resize-none bg-[#f8faff] border-0"
                            {...field}
                          />
                          <div
                            onClick={handleAttachClick}
                            className="text flex cursor-pointer justify-center bg-[#2CBCEF] items-center bottom-5 right-5 w-12 h-12 rounded-full absolute"
                          >
                            <Input
                              placeholder=""
                              accept=".pdf,.jpg,.jpeg,.png"
                              className="border-0 shadow-none p-0 hidden"
                              type="file"
                              ref={fileInputRef}
                              onChange={handleFileChange}
                            />
                            <IoMdAttach className="text-white" />
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {selectedFileName && (
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="text rounded-lg p-2 relative flex items-center gap-2">
                          <img src={pdf} alt="pdf file" className="text w-8" />
                          <span className="text relative pr-7 py-3">
                            <RxCross2 className="absolute text-2xl right-0 top-0" />
                            {selectedFileName}
                          </span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{selectedFileName}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                )}
                <button
                  type="submit"
                  className={`py-3 ${
                    form.watch("message") || form.watch("file")
                      ? "bg-[#2CBCEF] text-white"
                      : "bg-gray-100"
                  } rounded-lg flex items-center gap-2 px-4 justify-center`}
                >
                  Send
                  <IoIosSend className={``} />
                </button>
              </form>
            </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default SelectModalDialog;
