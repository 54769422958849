import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../components/shadch/ui/ui/card";
import pdf from "../../../assets/icons/pdf.svg";
import { CgFileDocument } from "react-icons/cg"; // Word document icon
import { RiFileExcel2Line } from "react-icons/ri"; // Excel file icon
import { BsFiletypePng, BsFiletypeJpg } from "react-icons/bs"; // Image icons

import { SlOptions } from "react-icons/sl";

const ClaimFileCard = ({ data }) => {
  const splitedWords = data?.adminUserName?.split(" ")[0]?.slice(0, 1) || "";

  const commentFiles = Array.isArray(data?.commentFiles) ? data.commentFiles : [];

  // Extract file details (name, type) from URL string
  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return null;

    try {
      const fileName = decodeURIComponent(fileUrl.split("/").pop());
      const fileType = fileName.split(".").pop()?.toLowerCase();
      return { fileUrl, fileName, fileType };
    } catch (error) {
      console.error("Error parsing file URL:", fileUrl, error);
      return null;
    }
  };

  // Determine icon or preview based on file type
  const getFileIcon = (type) => {
    switch (type) {
      case "pdf":
        return <img src={pdf} alt="PDF" className="w-8" />;
      case "png":
        return <BsFiletypePng className="w-8 h-8 text-blue-500" />;
      case "jpg":
      case "jpeg":
        return <BsFiletypeJpg className="w-8 h-8 text-blue-500" />;
      case "doc":
      case "docx":
        return <CgFileDocument className="w-8 h-8 text-gray-500" />;
      case "xls":
      case "xlsx":
        return <RiFileExcel2Line className="w-8 h-8 text-green-500" />;
      default:
        return <CgFileDocument className="w-8 h-8 text-gray-400" />; // Default icon
    }
  };

  const formatTime = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } catch (error) {
      console.error("Invalid date:", dateString, error);
      return "Invalid time";
    }
  };

  return (
    <Card className="shadow-none bg-inherit my-4 border-0">
      <div className="text bg-white rounded-lg">
        <CardHeader>
          <CardTitle>
            <div className="text flex items-center justify-between">
              <div className="text flex items-center gap-3">
                <div className="text-white flex justify-center items-center rounded-full text-xs w-6 h-6 bg-[#EF4343]">
                  {splitedWords}
                </div>
                <div className="tex">{data?.adminUserName}</div>
              </div>
              <SlOptions />
            </div>
          </CardTitle>
          <CardDescription>
            <div className="text mt-2">
              {commentFiles.length > 0 ? (
                commentFiles.map((fileUrl, index) => {
                  const fileDetails = getFileDetails(fileUrl);
                  if (!fileDetails) return <p key={index} className="text-red-500">Invalid file</p>;

                  const { fileUrl: url, fileName, fileType } = fileDetails;
                  const FileIcon = getFileIcon(fileType);

                  return (
                    <div
                      key={index}
                      className="text bg-[#2CBCEF14] rounded-lg p-2 flex items-center gap-2 my-2"
                    >
                    
                      
                      {fileType === "png" || fileType === "jpg" || fileType === "jpeg" ? (
                        <a href={url} target="_blank" className=" w-full" rel="noopener noreferrer">
                          <img
                            src={url}
                            alt={fileName}
                            className=" w-[100%] max-h-[200px] object-cover rounded-lg"
                          />
                        </a>
                      ) : fileType === "pdf" ? (
                        <>
                        {fileType === "pdf" && FileIcon }
                        <a href={url}   target="_blank" rel="noopener noreferrer"  className="text">
                          {fileName}
                        </a>
                        {/* <embed
                          src={url}
                          type="application/pdf"
                          className="w-full h-8 border rounded-lg"
                        /> */}
                         </>
                      ) : (
                        <a href={url} download={fileName} className="text">
                          {fileName}
                        </a>
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="text-red-500">No valid file attached</p>
              )}
            </div>
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="text-sm">{data?.commentText}</div>
        </CardContent>
      </div>
      <div className="text-end my-1 text-sm">
        {data?.createdAt ? formatTime(data.createdAt) : "Unknown time"}
      </div>
      <CardFooter className="flex justify-between">
        <button className="text"> </button>
      </CardFooter>
    </Card>
  );
};
export default ClaimFileCard;
